<template>
  <CRUDContainer
    title="Vehicle Details"
    :showAdd="false"
    :showDelete="false"
    :showRefresh="!!vehicleDetails.id"
    :showEdit="!isEditing"
    :showSave="isEditing"
    :showCancel="isEditing"
    :refreshLoading="isLoading"
    @edit="edit()"
    @refresh="refresh()"
    @cancel="cancel()"
    @save="save()"
    class="align-self-stretch"
    flat
    elevation="4"
  >
    <div class="pa-4">
      <v-text-field
        v-if="isEditing"
        label="Name"
        v-model="vehicleDetails.name"
        outlined
        solo
        dense
        placeholder="Vehicle Name"
        class="vehicle-name edit"
      />
      <div v-else class="vehicle-name ">{{ vehicleDetails.name }}</div>
      
      <div class="d-flex justify-end">
        <v-checkbox
          label="Active"
          v-model="vehicleDetails.isActive"
          :readonly="!isEditing"
          :outlined="isEditing"
          :class="{ 'display-only': !isEditing }"
          dense
          class="ma-0 pa-0"
        />
      </div>

      <div class="d-flex flex-wrap">
        <v-card outlined class="ma-2 flex-grow-1 vehicle-detail-group">
          <v-card-title>Vehicle Info</v-card-title>

          <v-card-text>
            <v-text-field
              label="Make"
              v-model="vehicleDetails.make"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              autocomplete="off"              
              dense
            />
            <v-text-field
              label="Model"
              v-model="vehicleDetails.model"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              autocomplete="off"
              dense
            />
            <v-text-field
              label="Model Year"
              v-model="vehicleDetails.modelYear"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              autocomplete="off"
              dense
            />
            <v-text-field
              label="VIN"
              v-model="vehicleDetails.vin"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              autocomplete="off"
              dense
            />
            <v-text-field
              label="License Plate"
              v-model="vehicleDetails.licensePlateNum"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              autocomplete="off"
              dense
            />
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-2 flex-grow-1 vehicle-detail-group">
          <v-card-title>Purchase Info</v-card-title>
          <v-card-text>
            <v-text-field
              v-if="isEditing"
              type="date"
              label="Purchase Date"
              v-model="vehicleDetails.purchaseDate"
              outlined
              dense
              autocomplete="off"
            />
            <v-text-field
              v-else
              type="text"
              label="Purchase Date"
              v-model="vehicleDetails.purchaseDate"
              readonly
              class="display-only"
              dense
              autocomplete="off"
            />

            <v-text-field
              label="Purchase Location"
              v-model="vehicleDetails.purchaseLocation"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
            <v-text-field
              label="Purchase Amount"
              v-model="vehicleDetails.purchaseAmount"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
            <v-text-field
              type="number"
              label="Starting Mileage"
              v-model="vehicleDetails.startingMileage"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-2 flex-grow-1 vehicle-detail-group">
          <v-card-title>Sale Info</v-card-title>
          <v-card-text>
            <v-text-field
              v-if="isEditing"
              type="date"
              label="Sold Date"
              v-model="vehicleDetails.soldDate"
              outlined
              dense
              autocomplete="off"
            />
            <v-text-field
              v-else
              type="text"
              label="Sold Date"
              v-model="vehicleDetails.soldDate"
              readonly
              class="display-only"
              dense
              autocomplete="off"
            />

            <v-text-field
              label="Sold To"
              v-model="vehicleDetails.soldTo"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
            <v-text-field
              label="Sold Amount"
              v-model="vehicleDetails.soldAmount"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
            <v-text-field
              type="number"
              label="Ending Mileage"
              v-model="vehicleDetails.endingMileage"
              :readonly="!isEditing"
              :outlined="isEditing"
              :class="{ 'display-only': !isEditing }"
              dense
              autocomplete="off"
            />
          </v-card-text>
        </v-card>
      </div>

      

      <div v-if="isEditing" class="d-flex justify-space-around">
        <v-btn @click.stop="cancel()">Cancel</v-btn>
        <v-btn @click.stop="save()" color="primary">Save</v-btn>
      </div>
    </div>
  </CRUDContainer>
</template>

<script>
import CRUDContainer from "@/components/Core/CRUDContainer.vue";
import vehicleService from "@/services/vehicle.service.js";

export default {
  components: {
    CRUDContainer,
  }, 
  computed: {
    vehicleId() {
      return Number(this.$route.params.id);
    },
  },  
  data: () => ({
    isEditing: false,
    vehicleDetails: "",
    isLoading: false,
    vehicle: null,
  }),
  methods: {
    async getVehicleInfo() {
      let result = await vehicleService.getVehicle(this.vehicleId);
      this.vehicle = result.data;
    },
    edit() {
      this.isEditing = true;
    },
    async refresh() {
        const result = await vehicleService.getVehicle(this.vehicleDetails.id);
        this.vehicleDetails = result.data;      
    },
    cancel() {
      this.vehicleDetails = { ...this.vehicle };
      this.isEditing = false;
    },
    async save() {
      try {
        this.isLoading = true;
        const result = !this.vehicleDetails.id
          ? await vehicleService.add(this.vehicleDetails)
          : await vehicleService.edit(this.vehicleDetails);

        this.vehicleDetails = result.data;
        this.isEditing = false;
      } finally {
        this.isLoading = false;
      }
    },
    delete() {},
  },
  watch: {
    vehicle: {
      handler() {
        this.vehicleDetails = { ...this.vehicle };
      },
      deep: true,
    },
  },
  mounted() {
    this.getVehicleInfo();
  },
};
</script>

<style scoped>
.button-bar {
  width: 80%;
}

.display-only {
  pointer-events: none;
}

.vehicle-name {
  font-size: 1.8em;
}
.vehicle-name:not(.edit) {
  height: 40px;
  padding: 9px 0px 57px 12px;
}

.vehicle-detail-group {
  flex-basis: 225px;
  flex-shrink: 1;
}
</style>
