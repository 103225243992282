var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({},'v-card',_vm.computedProps,false),[_c('v-card-title',{staticClass:"pa-0"},[_c('small',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('div',{staticClass:"toolbar-buttons d-flex"},[(_vm.showExtra1)?[_c('div',{staticClass:"extra1"},[(_vm.extra1Icon)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.extra1Disabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra1AriaLabel,"loading":_vm.extra1Loading,"disabled":_vm.extra1Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra1()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra1Icon))])],1)],1)]}}],null,false,371735081)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.extra1Tooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra1AriaLabel,"loading":_vm.extra1Loading,"disabled":_vm.extra1Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra1()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra1Icon))])],1)],1)]:_vm._e(),(_vm.showExtra2)?[_c('div',{staticClass:"extra2"},[(_vm.extra2Icon)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.extra2Disabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra2AriaLabel,"loading":_vm.extra2Loading,"disabled":_vm.extra2Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra2()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra2Icon))])],1)],1)]}}],null,false,2669776041)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.extra2Tooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra2AriaLabel,"loading":_vm.extra2Loading,"disabled":_vm.extra2Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra2()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra2Icon))])],1)],1)]:_vm._e(),(_vm.showExtra3)?[_c('div',{staticClass:"extra3"},[(_vm.extra3Icon)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.extra3Disabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra3AriaLabel,"loading":_vm.extra3Loading,"disabled":_vm.extra3Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra3()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra3Icon))])],1)],1)]}}],null,false,2902444457)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.extra3Tooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":_vm.extra3AriaLabel,"loading":_vm.extra3Loading,"disabled":_vm.extra3Disabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitExtra3()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.extra3Icon))])],1)],1)]:_vm._e(),(_vm.showAdd)?[_c('div',{staticClass:"add"},[(_vm.addTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.addDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Add","loading":_vm.addLoading,"disabled":_vm.addDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitAdd()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,false,1165514628)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.addTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Add","loading":_vm.addLoading,"disabled":_vm.addDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitAdd()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]:_vm._e(),(_vm.showEdit)?[_c('div',{staticClass:"edit"},[(_vm.editTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.editDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Edit","loading":_vm.editLoading,"disabled":_vm.editDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitEdit()}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,767857886)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.editTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Edit","loading":_vm.editLoading,"disabled":_vm.editDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitEdit()}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]:_vm._e(),(_vm.showDelete)?[_c('div',{staticClass:"delete"},[(_vm.deleteTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.deleteDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Delete","loading":_vm.deleteLoading,"disabled":_vm.deleteDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitDelete()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)]}}],null,false,765504458)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.deleteTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Delete","loading":_vm.deleteLoading,"disabled":_vm.deleteDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitDelete()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)]:_vm._e(),(_vm.showSave)?[_c('div',{staticClass:"save"},[(_vm.saveTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.saveDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Save","loading":_vm.saveLoading,"disabled":_vm.saveDisabled,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.emitSave()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)]}}],null,false,686141692)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.saveTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Save","loading":_vm.saveLoading,"disabled":_vm.saveDisabled,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.emitSave()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)]:_vm._e(),(_vm.showRefresh)?[_c('div',{staticClass:"refresh"},[(_vm.refreshTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.refreshDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Refresh","loading":_vm.refreshLoading,"disabled":_vm.refreshDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitRefresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]}}],null,false,2251207967)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.refreshTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Refresh","loading":_vm.refreshLoading,"disabled":_vm.refreshDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitRefresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]:_vm._e(),(_vm.showCancel)?[_c('div',{staticClass:"cancel"},[(_vm.cancelTooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{'not-allowed': _vm.cancelDisabled}},on),[_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Cancel","loading":_vm.cancelLoading,"disabled":_vm.cancelDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitCancel()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}],null,false,3566164399)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.cancelTooltip))])]):_c('v-btn',{attrs:{"small":"","icon":"","aria-label":"Cancel","loading":_vm.cancelLoading,"disabled":_vm.cancelDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.emitCancel()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]:_vm._e()],2)],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('div',[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }