<template>
  <v-card v-bind="computedProps">
    <v-card-title class="pa-0">
        <small class="pl-4">{{title}}</small>

        <v-spacer></v-spacer>

        <v-toolbar dense flat>
            <v-spacer />
            <div class="toolbar-buttons d-flex">
                <template v-if="showExtra1">
                    <div class="extra1">
                        <v-tooltip v-if="extra1Icon" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': extra1Disabled}">
                                    <v-btn small icon :aria-label="extra1AriaLabel" @click.stop="emitExtra1()" :loading="extra1Loading" :disabled="extra1Disabled">
                                        <v-icon>{{extra1Icon}}</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{extra1Tooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon :aria-label="extra1AriaLabel" @click.stop="emitExtra1()" :loading="extra1Loading" :disabled="extra1Disabled">
                            <v-icon>{{extra1Icon}}</v-icon>
                        </v-btn>                              
                    </div>
                </template>
                <template v-if="showExtra2">
                    <div class="extra2">
                        <v-tooltip v-if="extra2Icon" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': extra2Disabled}">
                                    <v-btn small icon :aria-label="extra2AriaLabel" @click.stop="emitExtra2()" :loading="extra2Loading" :disabled="extra2Disabled">
                                        <v-icon>{{extra2Icon}}</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{extra2Tooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon :aria-label="extra2AriaLabel" @click.stop="emitExtra2()" :loading="extra2Loading" :disabled="extra2Disabled">
                            <v-icon>{{extra2Icon}}</v-icon>
                        </v-btn>                              
                    </div>
                </template>
                <template v-if="showExtra3">
                    <div class="extra3">
                        <v-tooltip v-if="extra3Icon" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': extra3Disabled}">
                                    <v-btn small icon :aria-label="extra3AriaLabel" @click.stop="emitExtra3()" :loading="extra3Loading" :disabled="extra3Disabled">
                                        <v-icon>{{extra3Icon}}</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{extra3Tooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon :aria-label="extra3AriaLabel" @click.stop="emitExtra3()" :loading="extra3Loading" :disabled="extra3Disabled">
                            <v-icon>{{extra3Icon}}</v-icon>
                        </v-btn>                              
                    </div>
                </template>
                <template v-if="showAdd">
                    <div class="add">
                        <v-tooltip v-if="addTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': addDisabled}">
                                    <v-btn small icon aria-label="Add" @click.stop="emitAdd()" :loading="addLoading" :disabled="addDisabled">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{addTooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon aria-label="Add" @click.stop="emitAdd()" :loading="addLoading" :disabled="addDisabled">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>                              
                    </div>
                </template>

                <template v-if="showEdit">
                    <div class="edit">
                        <v-tooltip v-if="editTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': editDisabled}">
                                    <v-btn small icon aria-label="Edit" @click.stop="emitEdit()" :loading="editLoading" :disabled="editDisabled">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>              
                            </template>
                            <div class="text-center">{{editTooltip}}</div>
                        </v-tooltip>
                        <v-btn v-else small icon aria-label="Edit" @click.stop="emitEdit()" :loading="editLoading" :disabled="editDisabled">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-if="showDelete">
                    <div class="delete">
                        <v-tooltip v-if="deleteTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': deleteDisabled}">
                                    <v-btn small icon aria-label="Delete" @click.stop="emitDelete()" :loading="deleteLoading" :disabled="deleteDisabled">
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>       
                                </div>           
                            </template>
                            <div class="text-center">{{deleteTooltip}}</div>
                        </v-tooltip>
                        <v-btn v-else small icon aria-label="Delete" @click.stop="emitDelete()" :loading="deleteLoading" :disabled="deleteDisabled">
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-if="showSave">
                    <div class="save">
                        <v-tooltip v-if="saveTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': saveDisabled}">
                                    <v-btn small icon aria-label="Save" @click.stop="emitSave()" :loading="saveLoading" :disabled="saveDisabled" color="primary">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{saveTooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon aria-label="Save" @click.stop="emitSave()" :loading="saveLoading" :disabled="saveDisabled" color="primary">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-if="showRefresh">
                    <div class="refresh">
                        <v-tooltip v-if="refreshTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': refreshDisabled}">
                                    <v-btn small icon aria-label="Refresh" @click.stop="emitRefresh()" :loading="refreshLoading" :disabled="refreshDisabled">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <div class="text-center">{{refreshTooltip}}</div>
                        </v-tooltip>
                        <v-btn v-else small icon aria-label="Refresh" @click.stop="emitRefresh()" :loading="refreshLoading" :disabled="refreshDisabled">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-if="showCancel">
                    <div class="cancel">
                        <v-tooltip v-if="cancelTooltip" left>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{'not-allowed': cancelDisabled}">
                                    <v-btn small icon aria-label="Cancel" @click.stop="emitCancel()" :loading="cancelLoading" :disabled="cancelDisabled">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>                        
                                </div>
                            </template>
                            <div class="text-center">{{cancelTooltip}}</div>
                        </v-tooltip> 
                        <v-btn v-else small icon aria-label="Cancel" @click.stop="emitCancel()" :loading="cancelLoading" :disabled="cancelDisabled">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>   
                    </div>
                </template>
            </div>
        </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0">
        <div>
            <slot name="default"></slot>
        </div>
    </v-card-text>
</v-card>
</template>

<script>

export default {
    name: "crud-container",
    props: {
        title: { default: '', type: String },
        loading: { default: false, type: Boolean},

        showAdd: {default: true, type: Boolean},
        showEdit: {default: true, type: Boolean},
        showDelete: {default: true, type: Boolean},
        showRefresh: {default: true, type: Boolean},
        showSave: {default: false, type: Boolean},
        showCancel: {default: false, type: Boolean},
        showExtra1: {default: false, type: Boolean},
        showExtra2: {default: false, type: Boolean},
        showExtra3: {default: false, type: Boolean},

        addDisabled: {default: false, type: Boolean},
        editDisabled: {default: false, type: Boolean},
        deleteDisabled: {default: false, type: Boolean},
        refreshDisabled: {default: false, type: Boolean},
        saveDisabled: {default: false, type: Boolean},
        cancelDisabled: {default: false, type: Boolean},
        extra1Disabled: {default: false, type: Boolean},
        extra2Disabled: {default: false, type: Boolean},
        extra3Disabled: {default: false, type: Boolean},

        addLoading: {default: false, type: Boolean},
        editLoading: {default: false, type: Boolean},
        deleteLoading: {default: false, type: Boolean},
        refreshLoading: {default: false, type: Boolean},
        saveLoading: {default: false, type: Boolean},
        cancelLoading: {default: false, type: Boolean},
        extra1Loading: {default: false, type: Boolean},
        extra2Loading: {default: false, type: Boolean},
        extra3Loading: {default: false, type: Boolean},

        addTooltip: {default: 'Add', type: String },
        editTooltip: {default: 'Edit', type: String },
        deleteTooltip: {default: 'Delete', type: String },
        refreshTooltip: {default: 'Refresh', type: String },
        saveTooltip: {default: 'Save', type: String },
        cancelTooltip: {default: 'Cancel', type: String },
        extra1Tooltip: {type: String },
        extra2Tooltip: {type: String },
        extra3Tooltip: {type: String },

        extra1AriaLabel: {type: String },
        extra2AriaLabel: {type: String },
        extra3AriaLabel: {type: String },
        extra1Icon: {type: String },
        extra2Icon: {type: String },
        extra3Icon: {type: String },
        
        // flat: {default: false, type: Boolean},
        // height: {default: '', type: String },
        // width: {default: '', type: String },
        // maxHeight: {default: '', type: String },
        // maxWidth: {default: '', type: String },
        // minHeight: {default: '', type: String },
        // minWidth: {default: '', type: String },
    },
    data () {
        return {
        }
    },
    computed: {
        computedProps: function () {
            return {
                ...this.$props,
            }
        },
    },
    methods: {
        emitAdd() {
            this.$emit("add");
        },
        emitEdit() {
            this.$emit("edit");
        },
        emitDelete() {
            this.$emit("delete");
        },
        emitRefresh() {
            this.$emit("refresh");
        },
        emitSave() {
            this.$emit("save");
        },
        emitCancel() {
            this.$emit("cancel");
        },
        emitExtra1() {
            this.$emit("extra1");
        },
        emitExtra2() {
            this.$emit("extra2");
        },
        emitExtra3() {
            this.$emit("extra3");
        }
    },    
}
</script>

<style scoped>
    .bordered{
        border: 1px solid #ccc;
    } 

    .v-toolbar__content {
        padding-right: 0;
    }

    .toolbar-buttons div {
        margin-left: 5px;
    }

    .not-allowed {
        cursor: not-allowed !important;
    }

    .extra1 {     
        order: 1;
    }
    .add {
        order: 2;
    }
    .edit {
        order: 3;
    }
    .delete {
        order: 4;
    }
    .refresh {
        order: 5;
    }
    .save {
        order: 6;
    }
    .cancel {
        order: 7;
        border-left: 1px solid #aaa;
        margin-left: 12px !important;
        padding-left: 8px;
    }
    
</style>