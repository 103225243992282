import axios from '@/plugins/axios.js';

const url = `/Vehicles`;

export default {
    getVehicles() {
        return axios.get(url);
    },
    getVehicle(id) {
        return axios.get(`${url}/${id}`);
    },
    add(vehicle) {
        return axios.post(url, vehicle);
    },
    edit(vehicle) {
        return axios.put(url, vehicle);
    },
}
